.about__section-image {
    display: flex;
    justify-content: center;
    border-radius: 80%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    transform: skew(15deg);
    transition: var(--transition);
}
.about__section-image img {
    max-width: 100%;
    transition: var(--transition);
  }
.about__section-image:nth-child(2) {
    transform:skew(-15deg);
}

.about__section-image:hover {
    transform: skew(0);
}

.about__section-content h1 {
    margin-bottom: 2rem;
}
.about__section-content p {
    margin-bottom: 1rem;
}

.about__story-container {
    display:grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
}
.about__mission-container{
    display:grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
}
.about__vision-container{
    display:grid;
    grid-template-columns: 50% 40%;
    gap: 10%;
}

.gallery__container {
    display:grid;
    max-width:100%;
    grid-template-columns: repeat(3, 1fr);
}
.gallery__container article img {
    max-width: 100%;
    height: auto;
  }
  
.gallery__container article {
    border: 1rem solid transparent;
    transition: var(--transition)
}
.gallery__container article:hover {
    border-color: var(--color-gray-500)
}
.buttons_nav {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-primary);
    color: white;
    padding: 20px;

  }
  
  .buttons_nav button {
    /* padding: 10px; */
    border: none;
    color: white;
    background-color: transparent;
    font-size: 16px;
    cursor: pointer;
  }

/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px) {
    .about__section-image {
        width: auto;
        align-items: center;

    }
    .about__section-content h1 {
        margin-bottom: 1.2rem;
    }
    .about__story-container, .about__vision-container, .about__mission-container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .about__vision .about__section-image {
        grid-row: 1;
    }
    .about__story-container {
        display:grid;
        grid-template-columns: 40% 50%;
        gap: 10%;
    }
    .about__mission-container{
        display:grid;
        grid-template-columns: 40% 50%;
        gap: 10%;
    }
    .about__vision-container{
        display:grid;
        grid-template-columns: 50% 40%;
        gap: 10%;
    }
}

/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
    .about__section-image {
        width: 80%;
        margin-inline: auto;
    }
    .about__story-container, .about__vision-container, .about__mission-container {
        gap: 2rem;
    }
    .about__story-container {
        display:grid;
        grid-template-columns: 40% 50%;
        gap: 10%;
    }
    .about__mission-container{
        display:grid;
        grid-template-columns: 40% 50%;
        gap: 10%;
    }
    .about__vision-container{
        display:grid;
        grid-template-columns: 50% 40%;
        gap: 10%;
    }
}

